import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        console.log('AuthProvider: Checking for token in localStorage', token);
        if (token) {
            console.log('AuthProvider: Token found, setting axios headers');
            axios.defaults.headers.common['Authorization'] = token;
            setUser({ token }); // Set user state with token
            // Fetch user data if needed
            // fetchUserData(token);
        }
    }, []);

    const login = (token) => {
        console.log('AuthProvider: Login called with token', token);
        localStorage.setItem('token', token);
        axios.defaults.headers.common['Authorization'] = token;
        setUser({ token }); // Set user state with token
        // Fetch user data if needed
        // fetchUserData(token);
    };

    const logout = () => {
        console.log('AuthProvider: Logout called');
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization'];
        setUser(null);
    };

    // Uncomment and implement this function if you want to fetch user data
    // const fetchUserData = async (token) => {
    //     try {
    //         const response = await axios.get('/api/user/me');
    //         setUser(response.data);
    //     } catch (error) {
    //         console.error('Error fetching user data:', error);
    //         logout(); // Logout if unable to fetch user data
    //     }
    // };

    console.log('AuthProvider: Current user state', user);

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
