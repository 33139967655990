import React, { useState, useEffect } from 'react';
import { Layout, Typography, Upload, Button, List, Radio, Input, message, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const { Content } = Layout;
const { Title, Text } = Typography;
const { Dragger } = Upload;

const SchoolEventsReminder = () => {
  const [fileList, setFileList] = useState([]);
  const [events, setEvents] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [reminderTiming, setReminderTiming] = useState('day-before');
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [error, setError] = useState(null);
  const [showUpload, setShowUpload] = useState(true);
  const [reminderConfirmed, setReminderConfirmed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (fileList.length > 0) {
      handleUpload();
    }
  }, [fileList]);

  const handleUpload = async () => {
    if (!fileList[0]) {
      message.error('Please select an image first');
      return;
    }

    setLoading(true);
    setShowUpload(false);
    setError(null);
    setLoadingText('Uploading calendar...');

    const formData = new FormData();
    formData.append('calendar', fileList[0]);

    try {
      setLoadingText('Analyzing calendar events...');
      const response = await axios.post('/api/school-events/process', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Received events:', response.data.events);
      setEvents(response.data.events);
    } catch (err) {
      console.error('Error processing calendar:', err);
      setError('Failed to process calendar. Please try again.');
      message.error('Failed to process calendar');
      setShowUpload(true);
    } finally {
      setLoading(false);
      setLoadingText('');
    }
  };

  const uploadProps = {
    beforeUpload: (file) => {
      return false;
    },
    onChange: (info) => {
      if (info.fileList.length > 0) {
        setFileList([info.fileList[0].originFileObj]);
      }
    },
    fileList,
    onRemove: () => {
      setFileList([]);
      setEvents([]);
      setShowUpload(true);
    },
  };

  const handleSetReminders = async () => {
    if (!phoneNumber) {
      message.error('Please enter a phone number');
      return;
    }

    try {
      setLoading(true);
      
      // First, save the events and set up reminders
      await axios.post('/api/school-events/save', {
        events,
        phoneNumber,
        reminderTiming
      });

      // Send an immediate test message
      await axios.post('/api/school-events/test-message', {
        phoneNumber,
        message: `Test: Your next event is ${events[0].event} on ${new Date(events[0].date).toLocaleDateString()}`
      });

      setReminderConfirmed(true);
    } catch (err) {
      setError('Failed to set up reminders. Please try again.');
      message.error('Failed to set up reminders');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Content style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      {!events.length > 0 && !reminderConfirmed && (
        <>
          <Title level={2}>School Events Reminder</Title>
          <Text style={{ marginBottom: '24px', display: 'block' }}>
            Upload your school calendar image and we'll help you set up reminders for all events.
          </Text>
        </>
      )}

      {showUpload && (
        <Dragger {...uploadProps} style={{ marginBottom: '24px' }}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="ant-upload-text">Click or drag calendar image to upload</p>
          <p className="ant-upload-hint">
            Supports: JPG, PNG, PDF
          </p>
        </Dragger>
      )}

      {loading && (
        <div style={{ textAlign: 'center', marginBottom: '24px' }}>
          <Spin size="large" />
          <Text style={{ display: 'block', marginTop: '16px' }}>
            {loadingText}
          </Text>
        </div>
      )}

      {error && (
        <Text type="danger" style={{ marginBottom: '24px', display: 'block' }}>
          {error}
        </Text>
      )}

      {events.length > 0 && !reminderConfirmed && (
        <>
          <Title level={3}>Found School Events</Title>
          <Text style={{ marginBottom: '16px', display: 'block' }}>
            Here are the upcoming events we found in your calendar:
          </Text>
          <List
            dataSource={events}
            renderItem={event => (
              <List.Item style={{ 
                padding: '12px 0',
                borderBottom: '1px solid #f0f0f0'
              }}>
                <Text>
                  <Text strong style={{ color: '#1890ff' }}>
                    📅 {new Date(event.date).toLocaleDateString('en-US', { 
                      month: 'long', 
                      day: 'numeric' 
                    })}
                  </Text>
                  <Text style={{ margin: '0 8px' }}>|</Text>
                  <Text>{event.event}</Text>
                </Text>
              </List.Item>
            )}
            style={{ 
              marginBottom: '32px',
              background: 'white',
              borderRadius: '8px',
              padding: '16px'
            }}
          />

          <Title level={3}>Get Text Reminders</Title>
          <Text style={{ marginBottom: '16px', display: 'block' }}>
            Enter your phone number to receive reminders for these events:
          </Text>
          <Input
            placeholder="Enter phone number"
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
            style={{ marginBottom: '16px' }}
          />

          <Radio.Group
            value={reminderTiming}
            onChange={e => setReminderTiming(e.target.value)}
            style={{ marginBottom: '24px', display: 'block' }}
          >
            <Radio value="day-before">Day before (6pm)</Radio>
            <Radio value="morning-of">Morning of (8am)</Radio>
          </Radio.Group>

          <Button 
            type="primary" 
            onClick={handleSetReminders}
            loading={loading}
          >
            Set Up Reminders
          </Button>
        </>
      )}

      {reminderConfirmed && (
        <>
          <div style={{ 
            textAlign: 'center',
            marginTop: '40px',
            padding: '32px',
            background: '#f6ffed',
            border: '1px solid #b7eb8f',
            borderRadius: '8px',
            maxWidth: '600px',
            margin: '40px auto'
          }}>
            <Text strong style={{ 
              fontSize: '18px',
              lineHeight: '1.5',
              display: 'block'
            }}>
              You are going to be reminded for all the events this month!
            </Text>
          </div>
          
          <div style={{ textAlign: 'center', marginTop: '24px' }}>
            <Button 
              type="primary"
              onClick={() => navigate('/')}
              size="large"
            >
              Back to Home
            </Button>
          </div>
        </>
      )}
    </Content>
  );
};

export default SchoolEventsReminder;
