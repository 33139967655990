import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Layout, Button, Space, Dropdown, Menu } from 'antd';
import { MenuOutlined, LogoutOutlined } from '@ant-design/icons';
import { AuthContext } from '../context/AuthContext';

const { Header } = Layout;

const AppHeader = () => {
    const { user, logout } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const dropdownMenu = (
        <Menu>
            <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
                Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <Header style={{ 
            display: 'flex', 
            justifyContent: 'flex-end', 
            alignItems: 'center', 
            padding: '0 50px',
            background: '#f0f2f5' // Light gray background
        }}>
            {user ? (
                <Dropdown overlay={dropdownMenu} placement="bottomRight" trigger={['click']}>
                    <Button 
                        icon={<MenuOutlined />} 
                        style={{ 
                            border: 'none',
                            background: 'none',
                            fontSize: '20px'
                        }}
                    />
                </Dropdown>
            ) : (
                <Space>
                    <Button><Link to="/register">Sign Up</Link></Button>
                    <Button><Link to="/login">Login</Link></Button>
                </Space>
            )}
        </Header>
    );
};

export default AppHeader;