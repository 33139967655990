import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import BedtimeStory from './pages/BedtimeStory';
import SchoolEventsReminder from './pages/SchoolEventsReminder';

const RoutesComponent = () => (
    <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/bedtime-story" element={<BedtimeStory />} />
        <Route path="/school-events" element={<SchoolEventsReminder />} />
    </Routes>
);

export default RoutesComponent;
