import React from 'react';
import { Layout, Typography, Menu } from 'antd';
import { Link, Routes, Route, useLocation } from 'react-router-dom';
import { BookOutlined, CalendarOutlined } from '@ant-design/icons';
import BedtimeStory from './BedtimeStory';
import SchoolEventsReminder from './SchoolEventsReminder';

const { Content } = Layout;
const { Title, Text } = Typography;

const menuItems = [
  {
    key: 'school-events',
    icon: <CalendarOutlined />,
    description: 'School Events Reminder'
  },
  {
    key: 'bedtime-story',
    icon: <BookOutlined />,
    description: 'Get a bedtime story'
  }
];

const Dashboard = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <Layout>
      <Content style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
        {isHomePage && (
          <>
            <Title level={2} style={{ marginTop: '0', marginBottom: '24px' }}>
              Welcome to your portal to all things kids related
            </Title>
          </>
        )}
        
        {isHomePage && (
          <Menu mode="vertical" style={{ background: 'transparent', fontSize: '20px' }}>
            {menuItems.map(item => (
              <Menu.Item key={item.key} icon={item.icon} style={{ height: 'auto', lineHeight: '1.5', padding: '12px 0' }}>
                <Link to={`/${item.key}`}>
                  <Text style={{ fontSize: '20px' }}>{item.description}</Text>
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        )}

        <Routes>
          <Route path="bedtime-story" element={<BedtimeStory />} />
          <Route path="school-events" element={<SchoolEventsReminder />} />
        </Routes>
      </Content>
    </Layout>
  );
};

export default Dashboard;
