import React, { useState } from 'react';
import { Layout, Typography, Card, Input, Slider, Button, Row, Col, Space, Alert } from 'antd';
import { 
  BookOutlined, 
  BugOutlined, 
  EnvironmentOutlined, 
  CompassOutlined, 
  RocketOutlined, 
  CarOutlined, 
  TrophyOutlined 
} from '@ant-design/icons';
import axios from 'axios';

const { Content } = Layout;
const { Title, Text, Paragraph } = Typography;

const topics = [
  { name: 'Animals', icon: <BugOutlined /> },
  { name: 'Nature', icon: <EnvironmentOutlined /> },
  { name: 'Beach', icon: <CompassOutlined /> },
  { name: 'Unicorns', icon: <RocketOutlined /> },
  { name: 'Trucks', icon: <CarOutlined /> },
  { name: 'Soccer', icon: <TrophyOutlined /> },
];

const BedtimeStory = () => {
  const [selectedTopic, setSelectedTopic] = useState('Animals');
  const [customTopic, setCustomTopic] = useState('');
  const [storyLength, setStoryLength] = useState(10);
  const [generatedStory, setGeneratedStory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleTopicSelect = (topic) => {
    setSelectedTopic(topic);
    setCustomTopic('');
  };

  const handleCustomTopicChange = (e) => {
    setCustomTopic(e.target.value);
    setSelectedTopic('');
  };

  const handleGenerateStory = async () => {
    const finalTopic = selectedTopic || customTopic;
    if (!finalTopic) {
      setError('Please select a topic or enter a custom one');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await axios.post('/api/bedtime-story/generate', {
        topic: finalTopic,
        length: storyLength
      });
      setGeneratedStory(response.data);
    } catch (error) {
      console.error('Error generating story:', error);
      setError('Failed to generate story. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Content style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <Title level={2} style={{ marginBottom: '32px' }}>Get a bedtime story</Title>

      {error && <Alert message={error} type="error" style={{ marginBottom: '20px' }} />}

      <Row gutter={[16, 16]}>
        {topics.map(topic => (
          <Col span={8} key={topic.name}>
            <Card
              hoverable
              style={{ 
                textAlign: 'center', 
                background: selectedTopic === topic.name ? '#e6f7ff' : 'white',
                cursor: 'pointer'
              }}
              onClick={() => handleTopicSelect(topic.name)}
            >
              <Space>
                {topic.icon}
                <Text>{topic.name}</Text>
              </Space>
            </Card>
          </Col>
        ))}
      </Row>

      <Input
        placeholder="Or enter a custom topic"
        value={customTopic}
        onChange={handleCustomTopicChange}
        style={{ marginTop: '20px' }}
      />

      <div style={{ marginTop: '20px' }}>
        <Text>Story Length: {storyLength} minutes</Text>
        <Slider
          min={5}
          max={15}
          defaultValue={10}
          marks={{ 5: '5 min', 10: '10 min', 15: '15 min' }}
          onChange={setStoryLength}
        />
      </div>

      <Button 
        type="primary" 
        icon={<BookOutlined />} 
        size="large"
        onClick={handleGenerateStory}
        disabled={(!selectedTopic && !customTopic) || loading}
        loading={loading}
        style={{ marginTop: '20px' }}
      >
        Get Story Outline
      </Button>

      {generatedStory && (
        <div style={{ marginTop: '32px' }}>
          <Title level={3}>{generatedStory.title}</Title>
          {generatedStory.content.split('\n').map((line, index) => (
            <Paragraph key={index} style={{ marginBottom: '16px' }}>
              {line}
            </Paragraph>
          ))}
        </div>
      )}
    </Content>
  );
};

export default BedtimeStory;
