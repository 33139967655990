import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Layout } from 'antd';
import AppHeader from './components/Header';
import Routes from './routes';
import { AuthProvider } from './context/AuthContext';

const { Content } = Layout;

const App = () => (
    <Router>
        <AuthProvider>
            <Layout className="layout" style={{ minHeight: '100vh' }}>
                <AppHeader />
                <Content className="site-content" style={{ marginTop: 0 }}>
                    <Routes />
                </Content>
            </Layout>
        </AuthProvider>
    </Router>
);

export default App;