import React, { useState, useContext } from 'react';
import { Form, Input, Button, Typography, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext'; // Make sure this path is correct

const { Title } = Typography;

const Login = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const navigate = useNavigate();
	const { login } = useContext(AuthContext);

	const handleSubmit = async () => {
		try {
			console.log('Starting login process');
			const res = await axios.post('/api/auth/login', { email, password });
			console.log('Login response:', res.data);
			
			const token = res.data.token;
			console.log('Saving token and updating auth context');
			localStorage.setItem('token', token);
			login(token);

			console.log('Navigation to dashboard');
			navigate('/dashboard');
		} catch (err) {
			console.error('Login error:', err);
			if (err.response) {
				console.error('Error response:', err.response.data);
				console.error('Error status:', err.response.status);
			} else if (err.request) {
				console.error('No response received:', err.request);
			} else {
				console.error('Error setting up request:', err.message);
			}
			message.error('Failed to log in. Please check your credentials and try again.');
		}
	};

	return (
		<div style={{ maxWidth: 300, margin: '100px auto' }}>
			<Title level={2}>Login</Title>
				<Form onFinish={handleSubmit}>
					<Form.Item
						name="email"
						rules={[{ required: true, message: 'Please input your Email!' }]}
					>
						<Input 
							prefix={<UserOutlined className="site-form-item-icon" />}
							placeholder="Email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</Form.Item>
					<Form.Item
						name="password"
						rules={[{ required: true, message: 'Please input your Password!' }]}
					>
						<Input.Password
							prefix={<LockOutlined className="site-form-item-icon" />}
							placeholder="Password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit" className="login-form-button" style={{ width: '100%' }}>
							Log in
						</Button>
						Or <Link to="/register">register now!</Link>
					</Form.Item>
				</Form>
		</div>
	);
};

export default Login;