import React, { useState, useContext } from 'react';
import { Form, Input, Button, Typography, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext'; // Make sure this path is correct

const { Title } = Typography;

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { login } = useContext(AuthContext); // Add this line

    const handleSubmit = async () => {
        try {
            console.log('Starting registration process');
            
            // Sign up the user
            const signupResponse = await axios.post('/api/auth/signup', { email, password });
            console.log('Signup response:', signupResponse.data);
            message.success('Account created successfully');

            console.log('Attempting login');
            // Log in the user
            const loginResponse = await axios.post('/api/auth/login', { email, password });
            console.log('Login response:', loginResponse.data);
            const token = loginResponse.data.token;
            
            console.log('Saving token and updating auth context');
            // Save the token and update auth context
            localStorage.setItem('token', token);
            login(token); // Make sure your AuthContext provides a login function

            console.log('Navigation to dashboard');
            navigate('/dashboard', { replace: true }, () => {
                console.log('Navigation completed');
            });
        } catch (err) {
            console.error('Error in registration process:', err);
            if (err.response) {
                console.error('Error response:', err.response.data);
                console.error('Error status:', err.response.status);
            } else if (err.request) {
                console.error('No response received:', err.request);
            } else {
                console.error('Error setting up request:', err.message);
            }
            message.error('Failed to create account or log in. Please try again.');
        }
    };

    return (
        <div style={{ maxWidth: 300, margin: '100px auto' }}>
            <Title level={2}>Sign Up</Title>
            <Form onFinish={handleSubmit}>
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please input your Email!' }]}
                >
                    <Input 
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your Password!' }]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: '100%' }}>
                        Sign Up
                    </Button>
                    Already have an account? <Link to="/login">Login now!</Link>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Register;